<template>
    <div class="component-wrap order_item-wrap" @click="onClick">
        <div class="order_item-header">
            <van-cell class="order_item-cell" center>
                <template #icon>
                    <!-- <van-image :src="require('@/assets/images/user/user_order-movie3.png')" width="24" height="24" style="margin-right:4px;"></van-image> -->
                    <van-icon :name="require('@/components/user/food-order-item/bmh.png')" size="24" style="margin-right: 4px;"></van-icon>
                </template>
                <template #title>
                    <span class="fw-7">影院小食</span>
                    <!-- <span>{{order.id}}</span> -->
                </template>
                <div class="fc-status">{{order.orderStatusText}}</div>
            </van-cell>
        </div>
        <div class="order_item-body fx-column">
            <div class="fx-row">
                <div class="order_item-logo">
                    <img :src="order.logo" style="height:100px;width: 120px;border-radius: 4px;"/>
                    <!-- <van-image :src="order.logo" width="120" height="100" style="border-radius: 4px;"></van-image> -->
                </div>
                <div class="order_item-info fx-column fx-around">
                    <span class="order_item-name">{{order.cinemaBmhBatchName}} × {{ order.qty }}</span>
                    <span class="fc-tips">{{order.itemDesc}}</span>
                    <span class="fc-tips">{{order.cinemaName}}</span>
                </div>
            </div>
            <div class="order_item-amount fx-column fx-end">
                <div>
                    <span class="amount">总金额：</span>
                    <span v-html="decimalPoint(new String(order.amount))"></span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { decimalPoint } from '@/libs/common-utils/common-utils';
export default {
    model: {
        prop: 'order',
        event: 'update',
    },
    props: {
        order: Object,
    },
    computed: {
    },
    mounted() {
        this.$nextTick(() => {
            if (this.order.remainTime) {
                setTimeout(() => {
                    this.$refs.countDown.start();
                }, 0);
            }
        })
    },
    methods: {
        decimalPoint,
        onClick() {
            // if (this.order.orderStatus == 'P' || this.order.orderStatus == 'S' || this.order.orderStatus == 'R' || this.order.orderStatus == 'T') {
            this.$emit('on-click', this.order.id);
            // }
        },
        finish() {
            this.$emit('count-down-finish', this.order);
        }
    },
}
</script>

<style lang="scss" scoped>
.order_item-wrap {
	background-color: #fff;
	overflow: hidden;
	border-radius: 10px;
	box-shadow: 0px 1px 3px #dcdcdc;
	margin-bottom: 8px;
	/deep/ .van-cell {
		padding: 6px 8px;
	}
	.order_item-body {
		padding: 10px;
		.order_item-info {
			margin-left: 8px;
			.order_item-name {
				font-size: 16px;
			}

			> * {
				line-height: 20px;
				height: 20px;
			}
		}
		.order_item-amount {
			font-size: 14px;
		}

		.order-name {
			font-size: 15px;
		}
	}
	.order_item-footer {
		padding: 0px 10px;
		> div {
			> div {
				padding-top: 4px;
				padding-bottom: 12px;
			}
		}
		.btn-mini {
			margin-left: 10px;
		}
	}
}
</style>
