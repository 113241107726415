<template>
    <div class="root-wrap">
        <div class="body-wrap">
            <div class="fill-box" style="padding: 8px;"  v-infinite-scroll="loadMore" infinite-scroll-disabled="busy" infinite-scroll-distance="10">
                <FoodOrderItem v-for="item,index in list" :key="index" :order="item" @on-click="goDetail"></FoodOrderItem>
                <div v-if="list.length > 0 && pagination.next == false" class="fx-middle fx-end">
                    <span class="fc-tips">没有更多的订单啦</span>
                </div>
                <van-empty v-else-if="list.length < 1" :image="require('@/assets/images/user/user_order-empty.png')" image-size="35%"></van-empty>
            </div>
        </div>
    </div>
</template>

<script>
import { getFoodOrderList, getFoodOrder } from '@api/order-request'
import FoodOrderItem from '@/components/user/food-order-item'
import pagination from '@/components/mixins/pagination';

export default {
    mixins: [pagination],
    data() {
        return {
            loading: {},
            list: [],
        }
    },
    methods: {
        getFoodOrderList() {
            this.loading = this.$toast.loading(this.$config.loadingMsg);
            getFoodOrderList().then((res) => {
                this.list = res.records;
                this.copyPagination(res);
            }).finally(() => {
                this.loading.clear();
            })
        },
        goDetail(orderId) {
            this.$goPage('food-order-detail', {
                orderId: orderId
            })
        },
        loadMore() {
            if (this.loading.value) return;
            if (this.pagination.next) {
                ++this.pagination.pageNo;
                this.getFoodOrderList();
            }
        },
        initPage() {
            this.list.length = 0;
            this.getFoodOrderList();
        }
    },
    created() {
        this.getFoodOrderList();
    },
    components: {
        FoodOrderItem
    }
}
</script>

<style lang="scss" scoped>
.body-wrap {
	flex: 1;
	position: relative;
}
</style>
